import React from 'react'
import '../style/style.css'
import Middle1 from '../Component/Middle1';
import About from '../Component/About';
import Service from '../Component/Service';
import Causes from '../Component/Causes';
import Donate from '../Component/Donate';
import Team from '../Component/Team';
import Volunteer from '../Component/Volunteer';
import Governance from '../Component/Governance';
import Contact from '../Component/Contact';


function HomePage() {

  return (
    <>
      <Middle1/>
      <About/>
      <Service/>
      <Causes/>
      <Donate/>
      <Team/>
      <Volunteer/>
      <Governance/>
      <Contact/>
    </>
  )
}

export default HomePage