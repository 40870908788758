import React, { useState } from 'react'
import { Link } from "react-router-dom";
import api from '../Service/api';

function Footer() {
    const [data, setData] = useState({
        email: "",
    });

    const handleChange = (event) => {
        setData(email => ({ ...email, [event.target.name]: event.target.value }));
    };

function handleSubmit(event) {
    event.preventDefault();
    console.log(data)
    api.post('/yadu/Newsletter/Subscribe', data)
        .then((res) => {
            console.log(res);
        })
        .catch((error) => {
            console.log(error);
        })

}

return (
    <>

<div className="footer">
    <div className="container">
        <div className="row">
            <div className="col-lg-3 col-md-6">
                <div className="footer-contact">
                    <h2>Our Head Office</h2>
                    <p><i className="fa fa-map-marker-alt"></i>Debihtola, Madhubani, Bihar-847404, India</p>
                    <p><i className="fa fa-phone-alt"></i>+91 9470883299</p>
                    <p><i className="fa fa-envelope"></i>jhatara123@gmail.com</p>
                    <div className="footer-social">
                        {/* <a className="btn btn-custom" href=""><i className="fab fa-twitter"></i></a> */}
                        <a className="btn btn-custom" href="https://www.facebook.com/profile.php?id=100086580764534" rel="noreferrer" target="_blank"><i className="fab fa-facebook-f"></i></a>
                        {/* <a className="btn btn-custom" href="" rel="noreferrer" target="_blank"><i className="fab fa-youtube"></i></a>
                        <a className="btn btn-custom" href="" rel="noreferrer" target="_blank"><i className="fab fa-instagram"></i></a>
                        <a className="btn btn-custom" href="" rel="noreferrer" target="_blank"><i className="fab fa-linkedin-in"></i></a> */}
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-6">
                <div className="footer-link">
                    <h2>Popular Links</h2>
                    <Link to="about">About Us</Link>
                    <Link to="contact">Contact Us</Link>
                    <Link to="causes">Popular Causes</Link>
                </div>
            </div>
            <div className="col-lg-3 col-md-6">
                <div className="footer-link">
                    <h2>Useful Links</h2>
                    <a href="https://merchant.razorpay.com/policy/KSPeH3zQAhUNlX/terms" rel="noreferrer" target="_blank">Terms of use</a>
                    <a href="https://merchant.razorpay.com/policy/KSPeH3zQAhUNlX/privacy" rel="noreferrer" target="_blank">Privacy policy</a>
                </div>
            </div>
            <div className="col-lg-3 col-md-6">
                <div className="footer-newsletter">
                    <h2>Newsletter</h2>
                    <form onSubmit={handleSubmit}>
                        <input className="form-control" type='email' name='email'  placeholder="Email goes here" required="required" value={data.email} onChange={handleChange} />
                        <button className="btn btn-custom" type='submit'>Submit</button>
                        <label>Don't worry, we don't spam!</label>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div className="container copyright">
        <div className="row">
            <div className="col-md-6">
                <p>&copy; <a href="http://yadutrust.org">http://yadutrust.org </a>, All Rights Reserved.</p>
            </div>
            <div className="col-md-6">
                {/* <p>Designed By <Link to="https://htmlcodex.com">HTML Codex</Link></p> */}
            </div>
        </div>
    </div>
</div>   
    </>
)
}

export default Footer