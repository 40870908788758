import React from 'react'
import GovernanceItem from './GovernanceItem'
const data=[
    {
        name:"Mr. Bipin Jha",
        profession:"Evangelist and Chairman of WorkWork Limited, United Kingdom",
        img:"assets/img/bipinjha.jpg",
    },
    {
        name:"Mr. Binay Jha",
        profession:"Co- Founder and CEO of Aapkilath",
        img:"assets/img/binayjha.jpg",
    },
    {
        name:"Mr Arvind Shahi",
        profession:"Chief Risk Officer of a large bank",
        img:"assets/img/arvindshahi.jpg",
    },
]
function Governance() {
    return (
        <>
            <div className="testimonial">
                <div className="container-fluid">
                    <div className="section-header text-center">
                        <p>Governance</p>
                    </div>
                    <div className="owl-carousel testimonials-carousel">
                    {data.map((item, index) => <GovernanceItem name={item.name} profession={item.profession} img={item.img} key={index}/> )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Governance