import React, { useState, useEffect } from 'react'
import { Parallax } from 'react-parallax';

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };   
    
    return (
        <p className="text">
            {isReadMore ? text.slice(0, 150) : text}
            <span onClick={toggleReadMore} className="read-or-hide">
                {isReadMore ? "...read more" : " show less"}
            </span>
        </p>
    );
};

function About() { 

    useEffect(() => {
        const body = document.querySelector('#root');
        body.scrollIntoView({
          behavior: 'smooth'
        }, 500)
      }, []);

      
    return (
        <>
            {/* <!-- About Start --> */}

            <div className="about">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <Parallax bgImage="./assets/img/About us photo.jpg" bgImageAlt="the cat" strength={400} bgImageStyle={{height: '700px', maxWidth: '705px'}}>
                                <p style={{ height: '700px' }}></p>
                            </Parallax>
                        </div>
                        <div className="col-lg-6">
                            <div className="section-header">
                                <p>Learn About Us</p>
                                <h2>Welcome to the YADU NANDAN SHIKSHA SEVA SANSTHAN Trust!</h2>
                            </div>
                            <div className="about-tab">
                                <ul className="nav nav-pills nav-justified">
                                    <li className="nav-item">
                                        <a className="nav-link active" data-toggle="pill" href="#tab-content-1">About</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="pill" href="#tab-content-2">Mission</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="pill" href="#tab-content-3">Vision</a>
                                    </li>
                                </ul>

                                <div className="tab-content">
                                    <div id="tab-content-1" className="container tab-pane active">

                                        <div className="R-container">
                                            <p> Education is the extremely powerful tool you can use to change the world and a remarkably effective way to break the cycle of poverty.Millions of kids remain out-of-school in India.</p>
                                            <ReadMore>
                                                However, we can lessen this gap by volunteering and making education accessible for the underprivileged, neglected, and poor children in society by spreading the phrase "Education is every child’s right to get".

                                                It empowers and helps an individual to learn about society, its rules and regulations, one's appropriate social behavior, and how to understand one's rights as a human and citizen.
                                                It works in a variety of ways by removing all the obstacles that restrict young children's development across India.
                                                Through our help, we hope to restore the equality that people experience at birth. Every person has the right to life and to have their basic needs met.
                                                Our team works tirelessly to provide youngsters and ignorant people with the necessities so they can be considered and valued as people.
                                            </ReadMore>

                                        </div>
                                    </div>
                                    <div id="tab-content-2" className="container tab-pane fade">
                                        <p>YADU NANDAN SHIKSHA SEVA SANSTHAN Trust aspires to promote equal education rights to both boys and girls, along with vocational skills among the underprivileged. We also award scholarships and offer financial support to deserving students who want to carry out higher studies in technical, medical professional, and general studies.</p>
                                    </div>
                                    <div id="tab-content-3" className="container tab-pane fade">
                                        <p>Through our educational development work, we wish to establish a research centre and a library-cum-reading room for the promotion of education and vocational skills. By doing social welfare work, we wish to increase the awareness of sanitation, public health, social development, and elderly care support in villages and rural cities.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About