import React, { useState } from 'react'

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p className="text">
            {isReadMore ? text.slice(0, 200) : text}
            <span onClick={toggleReadMore} className="read-or-hide">
                {isReadMore ? "...read more" : " show less"}
            </span>
        </p>
    );
};
function ServiceItem(props) {
    return (
        <>
            <div className="col-lg-4 col-md-6">
                <div className="service-item">
                    <div className="service-icon">
                        <img src={props.icon} alt="social service" height="60"/>
                    </div>
                    <div className="service-text">
                        <h3>{props.title}</h3>
                        <ReadMore>{props.detail}</ReadMore>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServiceItem