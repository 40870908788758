import React, { useEffect } from 'react'
import CausesItem from './CausesItem';

function Causes() {
    useEffect(() => {
        const body = document.querySelector('#root');
        body.scrollIntoView({
            behavior: 'smooth'
        }, 500)
    }, []);

const data=[
    {
        title:"Environment",
        detail:"Green is the new colour of humanity- Firmly believing in this mantra , we have planted 1200 Mahogany tress in last 1 year. The greener our planet better our humanity and sustainability",
        img:"assets/img/Banner5.jpg",
    }, 
    {
        title:"Social Harmony",
        detail:"The trust built the 1st Goddess Durga temple in the village. Goddess Durga is the symbol of strength, religious harmony and strong cultural ethos.",
        img:"assets/img/causes2.jpg",
    },
    {
        title:"Health for All",
        detail:"The Trust has taken this larger initiative to built a robust primary health care in the vicinity of the village. Now people in villages and rural areas will have a quick access to an affordable quality medical healthcare.",
        img:"assets/img/Banner4.jpg",
    },
    {
        title:"Roots",
        detail:"An initiative under which the trust has built a common social engagement roof called Mandap. Seniors from the village assemble here for all key decisions targetted towards betterment of village as well a place for religious and cultural hangouts.",
        img:"assets/img/roots.png",
    },
]
    return (
        <>
            <div className="causes">
                <div className="section-header text-center">
                    <h2>Let's know about what we are doing</h2>
                </div>
                <div className="container-fluid">
                {data.map((item, index) => <CausesItem title={item.title} detail={item.detail} img={item.img} key={index}/> )}
                </div>
            </div>
        </>
    )
}

export default Causes