import React from 'react';
import { Outlet } from 'react-router-dom'

import Header from './components/Component/Header';
import Footer from './components/Component/Footer';

function App() {

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

export default App;
