import React,{useState,useEffect} from 'react'
import $ from 'jquery'
import api from '../Service/api';
function Contact() {
    useEffect(() => {
        const body = document.querySelector('#root');
        body.scrollIntoView({
          behavior: 'smooth'
        }, 500)
      }, []);
    const [contact, setContact]=useState({
            
            name: "",
            emails: "",
            phone: "",
            message: "",
            enquiryType: "Enquiry"
    })
    
    const handleChange = (event) => {
        setContact(prev => ({ ...prev, [event.target.name]: event.target.value }));
    };

function handleSubmit(event) {
    event.preventDefault();
    
    api.post('/yadu/Enquiry/AddYaduEnquiry', contact)
        .then((res) => {
            console.log(res);
            $('#success').html("<div class='alert alert-success'>");
            $('#success > .alert-success').html("<button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;")
                    .append("</button>");
            $('#success > .alert-success')
                    .append("<strong>Your message has been sent. </strong>");
            $('#success > .alert-success')
                    .append('</div>');
        })
        .catch((error) => {
            console.log(error);
            $('#success').html("<div class='alert alert-danger'>");
            $('#success > .alert-danger').html("<button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;")
                    .append("</button>");
            $('#success > .alert-danger').append($("<strong>").text("Sorry " + contact.name + ", it seems that our server is not responding. Please try again later!"));
            $('#success > .alert-danger').append('</div>');
            
        })

}

    return (
        <>
            <div className="contact">
                <div className="section-header text-center">
                    <p>Get In Touch</p>
                    <h2>Contact for any query</h2>
                </div>
                <div className="container">
                    <div className="contact-img">
                        <img src="assets/img/contact.jpg" alt="contact" />
                    </div>
                    <div className="contact-form">
                        <form onSubmit={handleSubmit} name="sentMessage" id="contactForm" >
                            <div className="control-group">
                                <input type="text" className="form-control" id="name" placeholder="Your Name" required="required"  name='name' onChange={handleChange} value={contact.name} />
                                <p className="help-block text-danger"></p>
                            </div>
                            <div className="control-group">
                                <input type="email" className="form-control" id="email" placeholder="Your Email" required="required"  name='emails' onChange={handleChange} value={contact.emails}/>
                                <p className="help-block text-danger"></p>
                            </div>
                            <div className="control-group">
                                <input type="text" className="form-control" id="phone" placeholder="Phone" required="required" name='phone' onChange={handleChange} value={contact.phone} />
                                <p className="help-block text-danger"></p>
                            </div>
                            <div className="control-group">
                                <textarea className="form-control" id="message" placeholder="Message" required="required" name='message'  style={{overflowY: "scroll", height: "160px"}} onChange={handleChange} value={contact.message}></textarea>
                                <p className="help-block text-danger"></p>
                            </div>
                            <div id="success" style={{position:"relative" }}></div> 
                            <div>
                                <button className="btn btn-custom" type="submit" id="sendMessageButton">Send Message</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Contact