import React from 'react'

function CausesItem(props) {
    return (
        <>
            <div className="causes-item">
                <div className="causes-img">
                    <img src={props.img} alt="causes-logo" style={{ height: '250px' }} />
                </div>
                <div className="causes-text">
                    <h3>{props.title}</h3>
                    <p>{props.detail}</p>
                </div>
            </div>
        </>
    )
}

export default CausesItem