import React from 'react'
import Middle2 from '../Component/Mddle2'
import Service from '../Component/Service'
import Causes from '../Component/Causes'

function CausesPage() {
  return (
    <>
    <Middle2 title="Popular Causes" page="Causes" img="assets/img/page-header2.jpg"/>
    <Service/>
    <Causes/>
    </>
  )
}

export default CausesPage