import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './index.css';
import App from './App';
import HomePage from './components/Views/HomePage';
import AboutPage from './components/Views/AboutPage';
import CausesPage from './components/Views/CausesPage';
import ContactPage from './components/Views/ContactPage';
import NoPage from './components/Views/NoPage';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index path="/" element={<HomePage />}/>
        <Route path="about" element={<AboutPage />}/>
        <Route path="causes" element={<CausesPage />}/>
        <Route path="contact" element={<ContactPage />}/>
        <Route path="*" element={<NoPage />}/>
      </Route>
    </Routes>
  </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();