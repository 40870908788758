import React from 'react'

function GovernanceItem(props) {
    return (
        <>
            <div className="testimonial-item">
                <div className="testimonial-profile">
                    <img src={props.img} alt="testimonial-logo" />
                    <div className="testimonial-name">
                        <h3>{props.name}</h3>
                        <p>{props.profession}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GovernanceItem