import React,{useState} from 'react'
import { Parallax } from 'react-parallax'
import api from '../Service/api';

function Volunteer() {
    const [data, setData] = useState({
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa29",
        name: "",
        email: "",
        phone: "",
        whatsApp: "",
        motive: ""
    });

    const handleChange = (event) => {
        setData(email => ({ ...email, [event.target.name]: event.target.value }));
    };

function handleSubmit(event) {
    event.preventDefault();
    console.log(data);
    api.post('/yadu/Volunteer/AddVolunteer', data)
        .then((res) => {
            console.log(res);
        })
        .catch((error) => {
            console.log(error);
        })

}


  return (
    <>
            {/* <!-- Volunteer Start --> */}

            <Parallax blur={0} bgImage="assets/img/volunteer.jpg" bgImageAlt="the cat" strength={400}>
        <div className="volunteer">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-7">
                        <div className="volunteer-content">
                            <div className="section-header">
                                <p>Become A Volunteer</p>
                                <h2>Helping needy people and children is the greatest act of service.</h2>
                            </div>
                            <div className="volunteer-text">
                                <p>
                                You can join us in addressing the root causes of the issues if you wish to contribute to the welfare of humanity. Your life is commendable for the humanitarian work you will do.                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="volunteer-form">
                            <form onSubmit={handleSubmit}>
                                <div className="control-group">
                                    <input type="text" className="form-control" placeholder="Name" name='name' value={data.name} required="required" onChange={handleChange}/>
                                </div>
                                <div className="control-group">
                                    <input type="email" className="form-control" placeholder="Email" name='email' value={data.email} required="required" onChange={handleChange}/>
                                </div>
                                <div className="control-group">
                                    <input type="text" className="form-control" placeholder="Mob Number" name='phone' value={data.phone} required="required" onChange={handleChange}/>
                                </div>
                                <div className="control-group">
                                    <input type="text" className="form-control" placeholder="WhatsApp Number" name='whatsApp' value={data.whatsApp} required="required" onChange={handleChange}/>
                                </div>
                                <div className="control-group">
                                    <textarea className="form-control" placeholder="Why you want to become a volunteer?" name='motive' value={data.motive} required="required" onChange={handleChange}></textarea>
                                </div>
                                <div>
                                    <button className="btn btn-custom" type="submit">Become a volunteer</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        </Parallax>
        {/* <!-- Volunteer End --> */}
    </>
  )
}

export default Volunteer